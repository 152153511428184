import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap";
    
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { Application } from '@splinetool/runtime';
const canvas = document.getElementById('canvas3d');
const app = new Application(canvas);

gsap.registerPlugin(Flip,ScrollTrigger,ScrollToPlugin);

const loaderTitle = new SplitType('#titleLoader')
const textAnimations = document.querySelectorAll(".titles")
const linesAnimations = document.querySelectorAll(".lines")
const closeBtn = document.querySelector(".menu-btn")
const menu = document.querySelector(".menu")
const closeSec = document.querySelector('.close-btn')
const head = document.querySelector('header')
const reviews = document.querySelectorAll('.medalla img')
let modelBronze = 'https://prod.spline.design/YBrImgiNBqqVhyXj/scene.splinecode';
let modelSilver = 'https://prod.spline.design/tv293RTiaZdaUerG/scene.splinecode';
let modelCeramic = 'https://prod.spline.design/CZUtd94c3YEF6adv/scene.splinecode';

let models = [modelBronze, modelSilver, modelCeramic];
let modelHefesto = models[Math.floor(Math.random() * models.length)];


//lenis
const lenis = new Lenis({
  wheelMultiplier: 0.75,
  touchMultiplier: 0.8,
  syncTouch: true,
  autoResize: true
})

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time)=>{
  lenis.raf(time * 700)
})

gsap.ticker.lagSmoothing(0)


//Menu superior ocultar
let prevScrollPos = window.scrollY;

window.addEventListener("scroll", function() {
  const currentScrollPos = window.scrollY;
  
  if (prevScrollPos > currentScrollPos) {
    gsap.to(closeSec, { top: "0", duration: 0.1, ease: "power1.inOut" });
  } else {
    gsap.to(closeSec, { top: "-10vh", duration: 0.1, ease: "power1.inOut" });
  }
  
  prevScrollPos = currentScrollPos;
});


ScrollTrigger.matchMedia({
  // large
  "(min-width: 960px)": function () {

    //Botones scroll to section
    document.querySelectorAll(".menu-item").forEach((item, index) => {
      item.addEventListener("click", () => {
        gsap.to(window, {duration: 2.5, scrollTo:{y:"#section" + (index + 1), offsetY:16}, ease: "power3.inOut" });
        gsap.to(menu, { left: "-100%", delay: 0.3, duration: 0.6, ease: "power2.inOut" });
      });
    });

    //news 
    gsap.fromTo('.news-content', {
      xPercent: 17,
      ease: 'power2.out'
      },
      {
      xPercent: -20,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.news-section',
        pin: true,
        start: "center center",
        end: "+=1000",
        scrub: true,
        }
      }
    );

    //menu
    gsap.to('header', {
        x: '0',
        scrollTrigger: {
          trigger: '.hero',
          start: 'top 40%',
          end: 'top 20%',
          scrub: true,
        },
        ease: 'power1.inOut'
    });
    
    //logo
    gsap.to('.logo-cont img', {
      yPercent: -100,
      duration: 0.8,
      ease: 'power3.out'
    })

    //lines
    gsap.to('.left-line', {
      clipPath: 'polygon(100% 40%, 0% 50%, 100% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    })

    gsap.to('.right-line', {
      clipPath: 'polygon(0% 40%, 100% 50%, 0% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    })

    //hefesto name
    gsap.to('.name-cont-original img', {
      yPercent: -105,
      duration: 0.8,
      ease: 'power3.out'
    })

    //items
    gsap.to('.item-con', {
      opacity: 1,
      duration: 1.1
    })
    
    //loader on scroll
    gsap.to(loaderTitle.chars, {
      yPercent: -100,
      duration: 0.7,
      stagger: .018,
      ease: 'power3.out'
    })
    
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 90%',
        end: 'top center',
        toggleActions: 'play pause resume reverse'
      }
    });
    
    tl.to('.logo-cont img', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    })

    tl.to('.left-line', {
      clipPath: 'polygon(100% 40%, 100% 50%, 100% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    }, '<')

    tl.to('.right-line', {
      clipPath: 'polygon(0% 40%, 0% 50%, 0% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    }, '<')

    tl.to('.name-cont-original img', {
      yPercent: -250,
      duration: 0.6,
      ease: 'power2.in'
    } ,'<')

    tl.to('.item-con', {
      opacity: 0,
      duration: 0.8
    }, '<')
    
    //tl.to(loaderTitle.words, {
    //  yPercent: -200,
    //  duration: 0.6,
    //  ease: 'power2.in'
    //}, '<')

    tl.to('.arrow', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    //titles
    textAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "chars"
      });

      gsap.from(split.chars, {
        yPercent: 200,
        stagger: 0.03,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textLine,
          start: "top 85%",
          end: "top center",
          scrub: true
        }
      });
    });

    //phars
    linesAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "lines"
      });

      gsap.from(split.lines, {
        yPercent: 300,
        opacity: 0,
        duration: 1.1,
        ease: 'power2.out',
        stagger: 0.035,
        scrollTrigger: {
          trigger: textLine,
          start: 'top 80%',
          end: 'top center',
          toggleActions: 'play none resume reverse'
        }
      });
    });

    //model
    app
      .load(modelHefesto)
      .then(() => {
        const model = app.findObjectByName('model');
        const light = app.findObjectByName('light');
        //console.log(model);

        gsap.set(model.scale, { x: 2.5, y: 2.5, z: 2.5 });
        gsap.set(model.position, { x: 0, y: 0, z: 550 });
        gsap.set(model.rotation, { x: 0, y: 0, z: 0 });
      
        gsap.set(light.scale, { x: 1, y: 1, z: 1 });
        gsap.set(light.position, { x: 0, y: -100, z: 650 });
      
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'top center',
              end: 'top -10%',
              scrub: true
            }
          })
          .from(model.position, { x: 0, y: -200, z: 700, ease: 'power2.inOut' })
          .from(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'center center',
              endTrigger: '.industries-section',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 100, y: 50, z: 500, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: -0.8, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.areas-section',
              start: 'center center',
              endTrigger: '.team-sec',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: -150, y: 60, z: 400, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.6, z: 0, ease: 'power2.inOut' }, '<')
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.logros',
              start: 'top center',
              end: 'bottom 90%',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: 65, z: 450, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.ini-section',
              start: 'bottom center',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: 100, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.3, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.news-content',
              start: "center center",
              end: "+=1000",
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -30, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: -.2, y: -0.3, z: 0, ease: 'power1.inOut' }, '<');
      });

      //Cambio color ico y p del nav
      function createScrollTimeline(trigger, targetImg, targetP, endTrigger = null, end = null) {
        const scrollTrigger = {
          trigger: trigger,
          start: 'top center',
          end: 'bottom center',
          toggleActions: 'play reverse play reset'
        };
      
        if (endTrigger) {
          scrollTrigger.endTrigger = endTrigger;
        }
      
        if (end) {
          scrollTrigger.end = end;
        }
      
        gsap.timeline({
          scrollTrigger: scrollTrigger
        })
        .to(targetImg, { opacity: 1, ease: 'power2.inOut', duration: 0.5 })
        .to(targetP, { color: '#FAFBFF', ease: 'power2.inOut', duration: 0.5 }, '<');
      }
      
      createScrollTimeline('.hero', '.home-nav img', '.home-nav p');
      createScrollTimeline('.industries-section', '.global-nav img', '.global-nav p');
      createScrollTimeline('.areas-section', '.ind-nav img', '.ind-nav p');
      createScrollTimeline('.team-sec', '.team-nav img', '.team-nav p', '.logros', 'bottom center');
      createScrollTimeline('.ini-section', '.ini-nav img', '.ini-nav p');
      createScrollTimeline('.news-section', '.news-nav img', '.news-nav p', '.contact-section', 'top top');
      createScrollTimeline('.contact-section', '.cont-nav img', '.cont-nav p', null, 'bottom bottom');

  },

  

  // medium
  "(min-width: 768px) and (max-width: 959px)": function () {

    gsap.to(loaderTitle.chars, {
      yPercent: -200,
      duration: 1,
      stagger: 0.018,
      ease: 'power3.out'
    })

    gsap.to('header', {
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 40%',
        end: 'top 20%',
        scrub: true,
      },
      duration: 1,
      ease: 'power1.inOut'
    });

    //menu
    let tlMenu = gsap.timeline();

    tlMenu.from('.intro-text', {
      xPercent: -100,
      duration: 1,
      ease: 'power2.out',
      stagger: 0.035,
    })

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 90%',
        end: 'top center',
        toggleActions: 'play pause resume reverse'
      }
    });
    
    tl.to('.logo-cont img', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    })
    
    tl.to(loaderTitle.words, {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    tl.to('.mouse', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    //titles
    textAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "words,chars"
      });

      gsap.from(split.chars, {
        yPercent: 200,
        stagger: 0.03,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textLine,
          start: "top 85%",
          end: "top center",
          scrub: true
        }
      });
    });

    //phars
    linesAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "lines,words,chars"
      });

      gsap.from(split.lines, {
        yPercent: 300,
        opacity: 0,
        duration: 1.1,
        ease: 'power2.out',
        stagger: 0.035,
        scrollTrigger: {
          trigger: textLine,
          start: 'top 80%',
          end: 'top center',
          toggleActions: 'play none resume reverse'
        }
      });
    });

    //news 
    //gsap.fromTo('.news-content', {
    //  xPercent: 0,
    //  ease: 'power2.out'
    //  },
    //  {
    //  xPercent: -99,
    //  ease: 'power2.out',
    //  scrollTrigger: {
    //    trigger: '.news-section',
    //    pin: true,
    //    start: "center center",
    //    end: "+=1000",
    //    scrub: true,
    //    }
    //  }
    //);

    //model
    app
      .load(modelHefesto)
      .then(() => {
        const model = app.findObjectByName('model');
        const light = app.findObjectByName('light');
        //console.log(model);
        gsap.set(model.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(model.position, { x: 5, y: -100, z: 550 });
        gsap.set(model.rotation, { x: 0, y: 0, z: 0 });
      
        gsap.set(light.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(light.position, { x: 0, y: -100, z: 650 });
      
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'top center',
              end: 'top -5%',
              scrub: true
            }
          })
          .from(model.position, { x: 0, y: -300, z: 700, ease: 'power2.inOut' })
          .from(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'center center',
              endTrigger: '.industries-section',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 55, y: -120, z: 500, ease: 'power2.inOut' })
          .to(model.rotation, { x: -0.2, y: -0.6, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.areas-section',
              start: 'center center',
              endTrigger: '.team-sec',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 30, y: -100, z: 530, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: -0.3, z: 0, ease: 'power2.inOut' }, '<')
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.logros',
              start: 'top center',
              end: 'bottom 90%',
              scrub: true
            }
          })
          .to(model.position, { x: -30, y: -75, z: 490, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.3, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.ini-section',
              start: 'bottom center',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -25, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.news-content',
              start: "center center",
              end: "+=1000",
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -130, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: -.2, y: -0.3, z: 0, ease: 'power2.inOut' }, '<');
          
      });

  },

  // small
  "(max-width: 767px)": function () {


    //Menu close on mobile
    closeBtn.addEventListener("click", function() {
      gsap.to(head, {
        left: head.style.left === "0px" || head.style.left === "0%" ? "-100%" : "0",
        duration: 0.6,
        ease: "power2.inOut"
      });

    });

    //Botones menu
    document.querySelectorAll(".menu-item").forEach((item, index) => {
      item.addEventListener("click", () => {
        gsap.to(window, {duration: 2.5, scrollTo:{y:"#section" + (index + 1), offsetY:16}, ease: "power3.inOut" });
        gsap.to(head, { left: "-100%", delay: 0.3, duration: 0.6, ease: "power2.inOut" });
      });
    });

    //logo
    gsap.to('.logo-cont img', {
      yPercent: -100,
      duration: 0.8,
      ease: 'power3.out'
    })

    //loader 
    gsap.to(loaderTitle.chars, {
      yPercent: -200,
      duration: 1,
      stagger: 0.018,
      ease: 'power3.out'
    })

    //lines
    gsap.to('.left-line', {
      clipPath: 'polygon(100% 40%, 0% 50%, 100% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    })

    gsap.to('.right-line', {
      clipPath: 'polygon(0% 40%, 100% 50%, 0% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    })

    //logo
    gsap.to('.name-cont-mobile img', {
      yPercent: -100,
      duration: 0.8,
      ease: 'power3.out'
    })

    //items
    gsap.to('.item-con', {
      opacity: 1,
      duration: 1.1
    })

    //loader items on scroll
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.hero',
        start: 'top 90%',
        end: 'top center',
        toggleActions: 'play pause resume reverse'
      }
    });
    
    tl.to('.logo-cont img', {
      yPercent: -220,
      duration: 0.6,
      ease: 'power2.in'
    })

    tl.to('.left-line', {
      clipPath: 'polygon(100% 40%, 100% 50%, 100% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    }, '<')

    tl.to('.right-line', {
      clipPath: 'polygon(0% 40%, 0% 50%, 0% 60%)',
      duration: 0.5,
      ease: 'power2.in'
    }, '<')

    tl.to('.name-cont-mobile img', {
      yPercent: -220,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')
    
    //tl.to(loaderTitle.words, {
    //  yPercent: -200,
    //  duration: 0.6,
    //  ease: 'power2.in'
    //}, '<')

    tl.to('.arrow', {
      yPercent: -200,
      duration: 0.6,
      ease: 'power2.in'
    }, '<')

    tl.to('.item-con', {
      opacity: 0,
      duration: 0.8
    }, '<')

    //titles
    textAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "words,chars"
      });

      gsap.from(split.chars, {
        yPercent: 200,
        stagger: 0.03,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textLine,
          start: "top 85%",
          end: "top center",
          scrub: true
        }
      });
    });

    //phars
    linesAnimations.forEach((textLine) => {
      const split = new SplitType(textLine, {
        types: "lines,words,chars"
      });

      gsap.from(split.lines, {
        yPercent: 300,
        opacity: 0,
        duration: 1.1,
        ease: 'power2.out',
        stagger: 0.035,
        scrollTrigger: {
          trigger: textLine,
          start: 'top 85%',
          end: 'top center',
          toggleActions: 'play none resume reverse'
        }
      });
    });

    //news 
    //gsap.fromTo('.news-content', {
    //  xPercent: 0,
    //  ease: 'power2.out'
    //  },
    //  {
    //  xPercent: -99,
    //  ease: 'power2.out',
    //  scrollTrigger: {
    //    trigger: '.news-section',
    //    pin: true,
    //    start: "center center",
    //    end: "+=1000",
    //    scrub: true,
    //    }
    //  }
    //);

    //model
    app
      .load(modelHefesto)
      .then(() => {
        const model = app.findObjectByName('model');
        const light = app.findObjectByName('light');
        //console.log(model);
        gsap.set(model.scale, { x: 2, y: 2, z: 2 });
        gsap.set(model.position, { x: 5, y: 0, z: 550 });
        gsap.set(model.rotation, { x: 0, y: 0, z: 0 });
      
        gsap.set(light.scale, { x: 0.8, y: 0.8, z: 0.8 });
        gsap.set(light.position, { x: 0, y: -100, z: 650 });
      
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'top center',
              end: 'top -5%',
              scrub: true
            }
          })
          .from(model.position, { x: 0, y: -200, z: 700, ease: 'power2.inOut' })
          .from(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: 'center center',
              endTrigger: '.industries-section',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 55, y: -20, z: 500, ease: 'power2.inOut' })
          .to(model.rotation, { x: -0.2, y: -0.6, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.areas-section',
              start: 'center center',
              endTrigger: '.team-sec',
              end: 'top top',
              scrub: true
            }
          })
          .to(model.position, { x: 30, y: 0, z: 530, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: -0.3, z: 0, ease: 'power2.inOut' }, '<')
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.logros',
              start: 'top center',
              end: 'bottom 90%',
              scrub: true
            }
          })
          .to(model.position, { x: -30, y: 25, z: 490, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0.3, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.ini-section',
              start: 'bottom center',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: 75, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: 0, y: 0, z: 0, ease: 'power2.inOut' }, '<');
        
        gsap.
          timeline({
            scrollTrigger: {
              trigger: '.news-content',
              start: "center center",
              end: "+=1000",
              scrub: true
            }
          })
          .to(model.position, { x: 0, y: -30, z: 550, ease: 'power2.inOut' })
          .to(model.rotation, { x: -.2, y: -0.3, z: 0, ease: 'power2.inOut' }, '<');
          
      });

      //Resaltar ico y p del nav
      function createScrollTimeline(trigger, targetImg, targetP) {
        gsap.timeline({
          scrollTrigger: {
            trigger: trigger,
            start: 'top center',
            end: 'bottom center',
            toggleActions: 'play reverse play reset'
          }
        })
        .to(targetImg, { opacity: 1, ease: 'power2.inOut', duration: 0.5 })
        .to(targetP, { color: '#FAFBFF', ease: 'power2.inOut', duration: 0.5 }, '<');
      }
      
      createScrollTimeline('.hero', '.home-nav img', '.home-nav p');
      createScrollTimeline('.industries-section', '.global-nav img', '.global-nav p');
      createScrollTimeline('.areas-section', '.ind-nav img', '.ind-nav p');
      createScrollTimeline('.team-sec', '.team-nav img', '.team-nav p');
      createScrollTimeline('.ini-section', '.ini-nav img', '.ini-nav p');
      createScrollTimeline('.news-section', '.news-nav img', '.news-nav p');
      createScrollTimeline('.contact-section', '.cont-nav img', '.cont-nav p');
      
    

  },

  // all
  all: function () {
    
    function handleMouseEnter() {
      if (this.querySelector('p').style.color !== 'rgb(250, 251, 255)' && 
          this.querySelector('.ico-container img').style.opacity !== '1') {
        this.querySelector('p').style.color = '#777980';
        this.querySelector('.ico-container img').style.opacity = '0.4';
      }
    }
    
    function handleMouseLeave() {
      if (this.querySelector('p').style.color !== 'rgb(250, 251, 255)' && 
          this.querySelector('.ico-container img').style.opacity !== '1') {
        this.querySelector('p').style.color = '#42454D';
        this.querySelector('.ico-container img').style.opacity = '0.2';
      }
    }
    
    document.querySelectorAll('.menu-item').forEach(item => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });

    reviews.forEach(review => {
      gsap.from(review, {
        yPercent: 125,
        duration: 1.1,
        ease: 'power2.inOut',
        scrollTrigger: { 
          trigger: '.logros',
          start: 'top 50%',
          toggleActions: 'play none resume reverse'
        }
      });
    });
    
  },
});